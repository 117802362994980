import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { API_BASE_URL } from '../services/api';
import { FaTelegram, FaDiscord } from 'react-icons/fa';
import './FindPage.css';

function FindPage() {
  const [activeBots, setActiveBots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'deployed_at', direction: 'desc' });

  useEffect(() => {
    fetchActiveBots();
  }, []);

  const fetchActiveBots = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/active-bots`); 
      if (!response.ok) {
        console.log('No active bots endpoint, returning empty array');
        setActiveBots([]);
        return;
      }
      const data = await response.json();
      setActiveBots(data.bots);
    } catch (error) {
      console.error('Error fetching active bots:', error);
      setActiveBots([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (key) => {
    setSortConfig({
      key,
      direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
    });
  };

  const sortedBots = [...activeBots].sort((a, b) => {
    if (sortConfig.direction === 'asc') {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    }
    return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
  });

  const filteredBots = sortedBots.filter(bot => 
    bot.model_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    bot.from_.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const PlatformIcon = ({ platform }) => {
    return platform === 'telegram' ? 
      <FaTelegram className="platform-icon telegram" /> : 
      <FaDiscord className="platform-icon discord" />;
  };

  if (isLoading) return <div className="loading">Loading active bots...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="find-page">
      <h1>Find Active Bots</h1>
      
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search bots..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <Table striped bordered hover responsive className="bots-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('model_name')}>Model Name</th>
            <th onClick={() => handleSort('from_')}>Source</th>
            <th onClick={() => handleSort('platform')}>Platform</th>
            <th onClick={() => handleSort('deployed_at')}>Deployed At</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredBots.map((bot) => (
            <tr key={bot.id}>
              <td>{bot.model_name}</td>
              <td>{bot.from_}</td>
              <td>
                <PlatformIcon platform={bot.platform} />
                {bot.platform}
              </td>
              <td>{new Date(bot.deployed_at).toLocaleString()}</td>
              <td>
                <span className={`status-badge ${bot.status}`}>
                  {bot.status}
                </span>
              </td>
              <td>
                {bot.platform === 'telegram' && bot.bot_name && (
                  <a 
                    href={`https://t.me/${bot.bot_name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bot-link telegram"
                  >
                    Open in Telegram
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default FindPage;