import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getBotTemplate, deployBot, stopBot, API_BASE_URL } from '../services/api';
import './TemplateDetailsPage.css';

function TemplateDetailsPage() {
  const navigate = useNavigate();
  const [template, setTemplate] = useState(null);
  const [error, setError] = useState(null);
  const [config, setConfig] = useState({});
  const [token, setToken] = useState('');
  const [isDeployed, setIsDeployed] = useState(false);
  const [platform, setPlatform] = useState('telegram');
  const [supportedPlatforms, setSupportedPlatforms] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    async function fetchTemplate() {
      try {
        const data = await getBotTemplate(id);
        setTemplate(data);
        setSupportedPlatforms(data.supported_platforms || ["telegram"]);
        initializeConfig(data.config_schema);
      } catch (err) {
        console.error('Error in fetchTemplate:', err);
        setError(`Failed to load template details: ${err.message}`);
      }
    }
    fetchTemplate();
  }, [id]);

  const initializeConfig = (schema) => {
    const initialConfig = {};
    Object.keys(schema).forEach(key => {
      initialConfig[key] = schema[key].type === 'array' ? [] : '';
    });
    setConfig(initialConfig);
  };

  const handleConfigChange = (key, value, index = null) => {
    if (index !== null) {
      setConfig(prev => ({
        ...prev,
        [key]: prev[key].map((item, i) => i === index ? value : item)
      }));
    } else {
      setConfig(prev => ({ ...prev, [key]: value }));
    }
  };

  const addArrayItem = (key) => {
    setConfig(prev => ({ ...prev, [key]: [...prev[key], ''] }));
  };

  const removeArrayItem = (key, index) => {
    setConfig(prev => ({
      ...prev,
      [key]: prev[key].filter((_, i) => i !== index)
    }));
  };

  const renderConfigFields = () => {
    if (!template) return null;

    return Object.entries(template.config_schema).map(([key, schema]) => (
      <div key={key} className="input-group">
        <label>{schema.label}:</label>
        <p className="input-description">{schema.description}</p>
        {schema.type === 'array' ? (
          <div className="array-input-container">
            {config[key].map((item, index) => (
              <div key={index} className="input-row">
                <input
                  type="text"
                  value={item}
                  onChange={(e) => handleConfigChange(key, e.target.value, index)}
                  placeholder={`Enter ${schema.label.toLowerCase()}`}
                />
                <button type="button" onClick={() => removeArrayItem(key, index)} className="remove-btn">-</button>
              </div>
            ))}
            <button 
              type="button" 
              onClick={() => addArrayItem(key)} 
              className="add-btn"
            >
              {config[key].length === 0 ? `Add ${schema.label}` : `Add Another ${schema.label.slice(0, -1)}`}
            </button>
          </div>
        ) : schema.type === 'string' && key === 'intro' ? (
          <textarea
            value={config[key]}
            onChange={(e) => handleConfigChange(key, e.target.value)}
            placeholder={`Enter ${schema.label.toLowerCase()}`}
            rows={5}
            className="large-text-input"
          />
        ) : schema.type === 'string' && key === 'llm_provider' ? (
          <select
            value={config[key]}
            onChange={(e) => handleConfigChange(key, e.target.value)}
            className="enum-select"
          >
            <option value="">Select a provider</option>
            <option value="gpt">gpt</option>
            <option value="claude">claude</option>
            <option value="deepseek">deepseek</option>
          </select>
        ) : (
          <input
            type="text"
            value={config[key]}
            onChange={(e) => handleConfigChange(key, e.target.value)}
            placeholder={`Enter ${schema.label.toLowerCase()}`}
          />
        )}
      </div>
    ));
  };

  const handleDeploy = async (e) => {
    e.preventDefault();
    try {
      const data = await deployBot(id, { ...config, token, platform });
  
      // If deployment is successful
      setIsDeployed(true);
      alert('Bot deployed successfully! Please wait 15-30 seconds for the bot to start.');
    } catch (err) {
      console.error('Deploy error:', err);
  
      if (err.requiresSubscription) {
        // Redirect to subscription page with a message
        navigate('/subscription', { 
          state: { 
            message: "Active subscription required to deploy bots. Please subscribe to continue." 
          } 
        });
      } else if (err.status === 401) {
        setError('Please log in to deploy bots. Click here to log in.');
      } else {
        setError('Failed to deploy bot: ' + (err.message || 'Unknown error'));
      }
    }
  };

  const handleStop = async () => {
    if (!isAuthenticated) {
      window.location.href = `${API_BASE_URL}/login`;
      return;
    }
    try {
      await stopBot(token);
      setIsDeployed(false);
      alert('Bot stopped successfully!');
    } catch (err) {
      if (err.response?.status === 401) {
        setError('Please log in to manage bots. Click here to log in.');
      } else {
        setError('Failed to stop bot: ' + err.message);
      }
      console.error(err);
    }
  };

  const togglePlatform = () => {
    if (supportedPlatforms.includes('discord') && supportedPlatforms.includes('telegram')) {
      setPlatform(platform === 'telegram' ? 'discord' : 'telegram');
    } else {
      alert(`This template only supports ${supportedPlatforms.join(', ')}.`);
    }
  };

  if (error) return (
    <div 
      className="error-message" 
      onClick={() => error.includes('log in') && (window.location.href = `${API_BASE_URL}/login`)}
      style={{ cursor: error.includes('log in') ? 'pointer' : 'default' }}
    >
      {error}
    </div>
  );
  if (!template) return <div>Loading...</div>;

  return (
    <div className="template-details-page">
      <h1>{template.name}</h1>
      <div className="template-introduction">
        <h2>Introduction</h2>
        <p>{template.description || "No introduction available for this template."}</p>
      </div>
      <div className="platform-toggle">
        {supportedPlatforms.length > 1 ? (
          <button onClick={togglePlatform}>
            Switch to {platform === 'telegram' ? 'Discord' : 'Telegram'}
          </button>
        ) : (
          <p className="platform-support-message">This template only supports {supportedPlatforms[0]}.</p>
        )}
      </div>
      <form onSubmit={handleDeploy}>
        {renderConfigFields()}
        <div className="input-group">
          <label htmlFor="token">{platform === 'telegram' ? 'Telegram' : 'Discord'} Bot Token:</label>
          <div className="input-row">
            <input
              type="text"
              id="token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              required
            />
          </div>
          <div className="token-help">
            <a 
              href={platform === 'telegram' ? 'https://t.me/botfather' : 'https://discord.com/developers/applications'} 
              target="_blank" 
              rel="noopener noreferrer"
              className="token-help-link"
            >
              What is a {platform === 'telegram' ? 'Telegram' : 'Discord'} bot token?
            </a>
            <p className="token-instructions">
              {platform === 'telegram' 
                ? 'Message @BotFather on Telegram and use /newbot command to create a new bot and get its token.'
                : 'Go to Discord Developer Portal, create a new application, add a bot to it, and copy the bot token.'}
            </p>
          </div>
        </div>
        <div className="button-container">
          <button type="submit" className="action-btn deploy-btn">
            {isDeployed ? 'Redeploy Bot' : 'Deploy Bot'}
          </button>
          {isDeployed && (
            <button type="button" onClick={handleStop} className="action-btn stop-btn">
              Stop Bot
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default TemplateDetailsPage;
