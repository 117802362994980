import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import BotDetailsPage from './pages/BotDetailsPage';
import TemplateDetailsPage from './pages/TemplateDetailsPage';
import DeployHuggingFaceModel from './pages/DeployHuggingFaceModel';
import DeployFromTemplatesTutorial from './pages/DeployFromTemplatesTutorial';
import DeployHuggingFaceTutorial from './pages/DeployHuggingFaceTutorial';
import Navigation from './components/Navigation';
import BotManagement from './components/bots/BotManagement';
import TutorialPage from './pages/TutorialPage';
import BasicsTutorial from './components/BasicsTutorial';
import FindPage from './pages/FindPage';
import DeployPage from './pages/DeployPage';
import UploadPage from './pages/UploadPage';
import SubscriptionPage from './pages/SubscriptionPage';

function App() {
  return (
    <Router>
      <Navigation />
      <div style={{ paddingTop: '60px' }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/find" element={<FindPage />} />
          <Route path="/deploy" element={<DeployPage />} />
          <Route path="/upload" element={<UploadPage />} />
          <Route path="/my-bots" element={<BotManagement />} />
          <Route path="/bots/:botUsername" element={<BotDetailsPage />} />
          <Route path="/external_bots/telegram/:telegramAccount" element={<BotDetailsPage />} />
          <Route path="/external_bots/discord/:discordAccount" element={<BotDetailsPage />} />
          <Route path="/bot-templates/:id" element={<TemplateDetailsPage />} />
          <Route path="/deploy-huggingface/:modelName" element={<DeployHuggingFaceModel />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="/tutorial" element={<TutorialPage />} />
          <Route path="/tutorial/deploy-from-templates" element={<DeployFromTemplatesTutorial />} />
          <Route path="/tutorial/deploy-huggingface" element={<DeployHuggingFaceTutorial />} />
          <Route path="/tutorial/basics" element={<BasicsTutorial />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;