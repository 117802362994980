import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../services/api';
import Subscription from '../components/Subscription';
import './SubscriptionPage.css';
import { useLocation } from 'react-router-dom';

function SubscriptionPage() {
  const location = useLocation();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isReactivating, setIsReactivating] = useState(false);

  useEffect(() => {
    checkSubscription();
  }, []);

  const checkSubscription = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/check-subscription`, {
        credentials: 'include'
      });
      const data = await response.json();
      
      if (data.error) {
        setError(data.error);
      } else {
        setSubscriptionStatus(data);
      }
    } catch (err) {
      setError('Failed to check subscription status');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!window.confirm('Are you sure you want to cancel your subscription?')) {
      return;
    }

    try {
      setIsCancelling(true);
      const response = await fetch(`${API_BASE_URL}/cancel-subscription`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        await checkSubscription();
        alert('Subscription cancelled successfully. You will have access until the end of your billing period.');
      }
    } catch (err) {
      setError('Failed to cancel subscription');
    } finally {
      setIsCancelling(false);
    }
  };

  const handleReactivateSubscription = async () => {
    try {
      setIsReactivating(true);
      const response = await fetch(`${API_BASE_URL}/reactivate-subscription`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        await checkSubscription();
        alert('Subscription reactivated successfully!');
      }
    } catch (err) {
      setError('Failed to reactivate subscription');
    } finally {
      setIsReactivating(false);
    }
  };

  if (isLoading) return <div className="loading">Loading subscription details...</div>;
  if (error) return <div className="error-message">{error}</div>;

  const renderSubscriptionStatus = () => {
    if (!subscriptionStatus?.isSubscribed) {
      return <Subscription />;
    }

    if (subscriptionStatus.cancelAtPeriodEnd) {
      return (
        <div className="subscription-details cancelled">
          <h2>Cancelled Subscription</h2>
          <div className="subscription-info">
            <p className="status cancelled">Status: Cancelled</p>
            <p className="cancellation-notice">
              Your subscription will remain active until{' '}
              {new Date(subscriptionStatus.currentPeriodEnd * 1000).toLocaleDateString()}
            </p>
            <div className="reactivate-section">
              <p>Want to keep your subscription?</p>
              <button 
                className="reactivate-button"
                onClick={handleReactivateSubscription}
                disabled={isReactivating}
              >
                {isReactivating ? 'Reactivating...' : 'Reactivate Subscription'}
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="subscription-details active">
        <h2>Active Subscription</h2>
        <div className="subscription-info">
          <p className="status active">Status: Active</p>
          {subscriptionStatus.currentPeriodEnd && (
            <p>Next billing date: {new Date(subscriptionStatus.currentPeriodEnd * 1000).toLocaleDateString()}</p>
          )}
          <button 
            className="cancel-subscription-button"
            onClick={handleCancelSubscription}
            disabled={isCancelling}
          >
            {isCancelling ? 'Cancelling...' : 'Cancel Subscription'}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="subscription-page">
      <h1>Subscription Management</h1>
      {location.state?.message && (
        <div className="subscription-message">
          {location.state.message}
        </div>
      )}
      {renderSubscriptionStatus()}
    </div>
  );
}

export default SubscriptionPage;
