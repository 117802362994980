import React, { useState } from 'react';
import { API_BASE_URL } from '../services/api';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const stripePromise = loadStripe('pk_test_51QPJG6ApJXfNP2Za0cWb5YU1nCpbbSAG1BdM3DBg1fPY9n2LEGn98RY0jyEaBYvPYSjVv26eJVMMoyulpLWEwphl00BrRz9Lpx');

function SubscriptionForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        setError(error.message);
        return;
      }

      const response = await fetch(`${API_BASE_URL}/create-subscription`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ paymentMethodId: paymentMethod.id }),
      });

      const data = await response.json();
      
      if (data.error) {
        setError(data.error);
      } else {
        window.location.reload();
      }
    } catch (err) {
      setError('Subscription failed. Please try again.');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="subscription-form">
      <h2>Subscribe to Deploy Bots</h2>
      <p>$5/month - Deploy unlimited bots</p>
      <div className="card-element-container">
        <CardElement />
      </div>
      {error && <div className="error-message">{error}</div>}
      <button type="submit" disabled={processing}>
        {processing ? 'Processing...' : 'Subscribe Now'}
      </button>
    </form>
  );
}

export default function Subscription() {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionForm />
    </Elements>
  );
}
